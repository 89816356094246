(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("cheetah-grid"));
	else if(typeof define === 'function' && define.amd)
		define(["cheetah-grid"], factory);
	else if(typeof exports === 'object')
		exports["vueCheetahGrid"] = factory(require("cheetah-grid"));
	else
		root["vueCheetahGrid"] = factory(root["cheetahGrid"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__3__) {
return 