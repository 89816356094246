var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-grid" }, [
    _c(
      "div",
      { staticClass: "define" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _vm._t("layout-header"),
        _vm._v(" "),
        _vm._t("layout-body")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-42f315fe", { render: render, staticRenderFns: staticRenderFns })
  }
}